import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/thinkingai-logo.png";
import "../../css/Welcome-Pages/About-ThinkingAI.css";
import { Link } from "react-router-dom";

function AboutThinkingAI() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        // Check if user data exists in localStorage
        const userData = JSON.parse(localStorage.getItem("userData"));

        // Redirect if user data exists
        if (userData && userData.name && userData.email) {
            navigate("/welcome");
        }

        // Simulate loading delay
        setTimeout(() => {
            setLoading(false);
        }, 1500); // Adjust delay as needed
    }, [navigate]);

    return (
        <div className="Welcome">
            <header className="Welcome-header">
                {loading ? (
                    <div className="skeleton-logo" /> // Skeleton placeholder
                ) : (
                    <img src={logo} className="Welcome-logo" alt="logo" />
                )}
            </header>
            <div className="Welcome-Text">
                {loading ? (
                    <>
                        <div className="skeleton-text-line" />
                        <div className="skeleton-text-line wide" />
                    </>
                ) : (
                    <>
                        <h2>Hi! I'm ThinkingAI :)</h2>
                        <h2>May I be your Loyal friend with Emotion?</h2>
                    </>
                )}
            </div>
            {loading ? (
                <div className="skeleton-button" />
            ) : (
                <Link to="/features" className="Next-Button">Yes, Of course</Link>
            )}
        </div>
    );
}

export default AboutThinkingAI;
