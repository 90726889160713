import { BrowserRouter, Routes, Route } from "react-router-dom";

import AboutthinkingAI from "./Components/Welcome-Pages/About-ThinkingAI";
import ThinkingaiFeatures from "./Components/Welcome-Pages/ThinkingAI-Features";
import ThinkingaiDevicesIntegration from "./Components/Welcome-Pages/ThinkingAI-DeviceIntegrations";
import Detailsgathering from "./Components/Welcome-Pages/Details-Gathering";
import Dashboard from "./Components/Dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AboutthinkingAI />} />
          <Route path="/features" element={<ThinkingaiFeatures />} />
          <Route path="/devices-integration" element={<ThinkingaiDevicesIntegration />} />
          <Route path="/name-email" element={<Detailsgathering />} />
          <Route path="/welcome" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
