import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/Welcome-Pages/ThinkingAI-DeviceIntegrations.css";

// Importing logo and images
import logo from "../../images/thinkingai-logo.png";
import Mobilephones from "../../images/Integration-Images/Mobile-Phones.webp";
import Smarthomedevices from "../../images/Integration-Images/Smart-Home-Devices.webp";
import Yourpc from "../../images/Integration-Images/Your-PC.webp";
import ARvr from "../../images/Integration-Images/AR-VR.webp";

function ThinkingaiDevicesIntegration() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.name && userData.email) {
            navigate("/welcome");
        }
        setTimeout(() => setLoading(false), 2000); // Simulate loading delay
    }, [navigate]);

    return (
        <div className="device-integration-root">
            {/* Logo Header */}
            <header className="device-integration-logo-header">
                {loading ? <div className="logo-skeleton"></div> : <img src={logo} alt="ThinkingAI Logo" className="device-integration-logo-image" />}
            </header>

            {/* Desktop view */}
            <div className={`device-integration-features-desktop ${loading ? "loading" : ""}`}>
                <FeatureCard image={Mobilephones} text="Mobile Phones" loading={loading} />
                <FeatureCard image={Smarthomedevices} text="Smart Home Devices" loading={loading} />
                <FeatureCard image={Yourpc} text="Your PC" loading={loading} />
                <FeatureCard image={ARvr} text="AR & VR Systems" loading={loading} />
            </div>

            {/* Mobile view: Carousel of Features */}
            <div className={`device-integration-features-mobile ${loading ? "loading" : ""}`}>
                <FeatureCarousel
                    features={[
                        { image: Mobilephones, text: "Mobile Phones" },
                        { image: Smarthomedevices, text: "Smart Home Devices" },
                        { image: Yourpc, text: "PC" },
                        { image: ARvr, text: "AR & VR Systems" }
                    ]}
                    loading={loading}
                />
            </div>

            {/* Button Section */}
            <div className="device-integration-center-card">
                {loading ? (
                    <>
                        <div className="text-skeleton"></div>
                        <div className="button-skeleton"></div>
                    </>
                ) : (
                    <>
                        <h2>ThinkingAI can integrate your</h2>
                        <Link to="/name-email" className="device-integration-next-button">Get Me In</Link>
                    </>
                )}
            </div>
        </div>
    );
}

function FeatureCard({ image, text, loading }) {
    return (
        <div className="device-integration-feature-card">
            {loading ? (
                <div className="image-skeleton"></div>
            ) : (
                <img className="device-integration-feature-images" src={image} alt={text} />
            )}
            <p className="device-integration-feature-text">{loading ? <div className="text-skeleton"></div> : text}</p>
        </div>
    );
}

function FeatureCarousel({ features, loading }) {
    const [index, setIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState("fade-in");

    useEffect(() => {
        const timerId = setInterval(() => {
            setFadeClass("fade-out");
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % features.length);
                setFadeClass("fade-in");
            }, 500);
        }, 4000);

        return () => clearInterval(timerId);
    }, [features.length]);

    return (
        <div className="device-integration-feature-carousel">
            {loading ? (
                <div className="carousel-skeleton"></div>
            ) : (
                <>
                    <img
                        className={`device-integration-carousel-image ${fadeClass}`}
                        src={features[index].image}
                        alt={features[index].text}
                    />
                    <p className={`device-integration-carousel-text ${fadeClass}`}>{features[index].text}</p>
                </>
            )}
        </div>
    );
}

export default ThinkingaiDevicesIntegration;
