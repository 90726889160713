// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDaSW9mPLrPk3_suBoJi4f7KIyKHFyC1r8",
    authDomain: "thinking-ai.firebaseapp.com",
    projectId: "thinking-ai",
    storageBucket: "thinking-ai.firebasestorage.app",
    messagingSenderId: "991634796591",
    appId: "1:991634796591:web:6326747261d22bf70916da",
    measurementId: "G-FECWHQH67J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };