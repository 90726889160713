import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import "../css/Dashboard.css";
import thinkingailogo from "../images/thinkingai-logo.png";

function WelcomePage() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [messages, setMessages] = useState([{ id: 1, text: "Welcome to ThinkingAI!", type: "bot" }]);
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [renameChatId, setRenameChatId] = useState(null);
    const [newChatName, setNewChatName] = useState("");
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState(""); // State to hold the QR code data URL
    const navigate = useNavigate();

    // Load chat history from localStorage on component mount
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData.name) {
            navigate("/");
        }

        const storedChatHistory = JSON.parse(localStorage.getItem("chatHistory"));
        if (storedChatHistory) {
            setChatHistory(storedChatHistory);
            if (storedChatHistory.length > 0) {
                // Load the first chat by default
                setSelectedChatId(storedChatHistory[0].id);
                setMessages(storedChatHistory[0].messages || []);
            }
        }
    }, [navigate]);

    // Save chat history to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
    }, [chatHistory]);

    const handleSendMessage = async (userInput) => {
        if (!userInput.trim()) return;

        setMessages((prevMessages) => [...prevMessages, { id: Date.now(), text: userInput, type: "user" }]);
        setLoading(true);

        try {
            const response = await axios.post(
                "https://api-inference.huggingface.co/models/facebook/blenderbot-400M-distill",
                JSON.stringify({ inputs: userInput }),
                {
                    headers: {
                        Authorization: `Bearer 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`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Check if the response is structured as expected
            const botMessageText = response?.data?.generated_text ||
                (Array.isArray(response.data) && response.data[0]?.generated_text) ||
                "I'm not sure how to respond to that.";

            const newBotMessage = { id: Date.now(), text: botMessageText, type: "bot" };

            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, newBotMessage];
                updateChatMessagesInHistory(updatedMessages);
                return updatedMessages;
            });
        } catch (error) {
            console.error("Error fetching response from Hugging Face API:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { id: Date.now(), text: "Sorry, I couldn't process that.", type: "bot" }
            ]);
        } finally {
            setLoading(false);
        }
    };


    // Update chat history with new messages
    const updateChatMessagesInHistory = (newMessages) => {
        setChatHistory((prevChatHistory) =>
            prevChatHistory.map((chat) => {
                if (chat.id === selectedChatId) {
                    return { ...chat, messages: newMessages };
                }
                return chat;
            })
        );
    };

    const handleNewChat = () => {
        const newChat = { id: Date.now(), name: "New Chat", messages: [] };
        setChatHistory((prev) => [...prev, newChat]);
        setSelectedChatId(newChat.id);
        setMessages([{ id: 1, text: "New conversation started.", type: "bot" }]);
    };

    const handleDeleteChat = (chatId) => {
        setChatHistory((prev) => prev.filter((chat) => chat.id !== chatId));
        setSelectedChatId(null);
        setMessages([{ id: 1, text: "Welcome to ThinkingAI!", type: "bot" }]);
    };

    const startRenameChat = (chatId) => {
        setRenameChatId(chatId);
        const chat = chatHistory.find((ch) => ch.id === chatId);
        setNewChatName(chat?.name || "");
    };

    const handleRenameChat = () => {
        setChatHistory((prev) => prev.map((chat) => (chat.id === renameChatId ? { ...chat, name: newChatName } : chat)));
        setRenameChatId(null);
    };

    const handleSelectChat = (chatId) => {
        const selectedChat = chatHistory.find((chat) => chat.id === chatId);
        setSelectedChatId(chatId);
        setMessages(selectedChat?.messages || []);
    };

    return (
        <div className="welcome-page">
            {!isSidebarOpen && (
                <button className="sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>☰</button>
            )}
            <Sidebar
                isOpen={isSidebarOpen}
                closeSidebar={() => setIsSidebarOpen(false)}
                handleNewChat={handleNewChat}
                chatHistory={chatHistory}
                setMessages={setMessages}
                handleDeleteChat={handleDeleteChat}
                startRenameChat={startRenameChat}
                handleSelectChat={handleSelectChat}
                selectedChatId={selectedChatId}
            />
            <ProfileMenu setQrCodeDataUrl={setQrCodeDataUrl} />
            <div className="main-chat">
                <ChatArea messages={messages} loading={loading} />
                <ChatInput onSendMessage={handleSendMessage} />
            </div>
            {renameChatId && (
                <RenameModal
                    newChatName={newChatName}
                    setNewChatName={setNewChatName}
                    handleRenameChat={handleRenameChat}
                    closeModal={() => setRenameChatId(null)}
                />
            )}
            {qrCodeDataUrl && (
                <Popup
                    title="Connect with Other Devices"
                    message="Scan this code to connect with other devices!"
                    qrCodeDataUrl={qrCodeDataUrl}
                    onClose={() => setQrCodeDataUrl("")}
                />
            )}
        </div>
    );
}

function Sidebar({ isOpen, closeSidebar, handleNewChat, chatHistory, handleDeleteChat, startRenameChat, handleSelectChat, selectedChatId }) {
    const sidebarRef = useRef(null);
    const [optionsChatId, setOptionsChatId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                closeSidebar();
                setOptionsChatId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [closeSidebar]);

    const handleRenameChat = (chatId) => {
        startRenameChat(chatId);
        setOptionsChatId(null);
    };

    const handleDeleteChatOption = (chatId) => {
        handleDeleteChat(chatId);
        setOptionsChatId(null);
    };

    const handleSidebarClick = (e) => {
        e.preventDefault();
        setShowPopup(true);
    };

    return (
        <>
            <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
                <div className="sidebar-header">
                    <img src={thinkingailogo} alt="ThinkingAI Logo" className="sidebar-logo" />
                    <h2>ThinkingAI</h2>
                </div>
                <button className="new-chat-button" onClick={handleNewChat}>+ New Chat</button>
                <nav className="sidebar-nav">
                    <ul>
                        <li onClick={handleSidebarClick}>Settings</li>
                        <li onClick={handleSidebarClick}>Help & Support</li>
                        <li><b>Chat History</b></li>
                    </ul>
                    <ul>
                        {chatHistory.map((chat) => (
                            <li
                                key={chat.id}
                                onClick={() => handleSelectChat(chat.id)}
                                className={selectedChatId === chat.id ? "active" : ""}
                            >
                                {chat.name}
                                <span
                                    className="options-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOptionsChatId(chat.id === optionsChatId ? null : chat.id);
                                    }}
                                >
                                    ⋮
                                </span>
                                {optionsChatId === chat.id && (
                                    <div className="chat-options">
                                        <button onClick={() => handleRenameChat(chat.id)}>Rename</button>
                                        <button onClick={() => handleDeleteChatOption(chat.id)}>Delete</button>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            {showPopup && (
                <Popup
                    title="Under Development"
                    message="This feature is under development. We’ll let you know once it’s ready!"
                    onClose={() => setShowPopup(false)}
                    actionText="OK"
                />
            )}
        </>
    );
}

function RenameModal({ newChatName, setNewChatName, handleRenameChat, closeModal }) {
    const handleSave = () => {
        handleRenameChat();
        closeModal();
    };

    return (
        <div className="popup-overlay" onClick={closeModal}>
            <div className="popup-content rename-modal" onClick={(e) => e.stopPropagation()}>
                <h3>Rename Chat</h3>
                <input
                    type="text"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    placeholder="Enter new name"
                />
                <div className="popup-actions">
                    <button onClick={closeModal} className="popup-cancel-button">Cancel</button>
                    <button onClick={handleSave} className="popup-action-button">Save</button>
                </div>
            </div>
        </div>
    );
}

function ProfileMenu({ setQrCodeDataUrl }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [initial, setInitial] = useState("");
    const [showPopup, setShowPopup] = useState(null);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.name) {
            setInitial(userData.name.charAt(0).toUpperCase());
        }
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        setShowPopup(null);
        setTimeout(() => {
            navigate("/");
        }, 500);
    };

    const generateQRCode = async () => {
        const randomData = `0G96T8142N5965622`;
        const qrCodeDataUrl = await QRCode.toDataURL(randomData);
        setQrCodeDataUrl(qrCodeDataUrl);
        setShowPopup("connect");
    };

    return (
        <div className="profile-menu-container">
            <button className="profile-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {initial}
            </button>
            {isMenuOpen && (
                <div ref={menuRef} className="profile-menu-dropdown">
                    <p onClick={() => setShowPopup("logout")}>
                        <span className="menu-icon">🔓</span> Logout
                    </p>
                    <p onClick={generateQRCode}>
                        <span className="menu-icon">🔗</span> Connect with Other Devices
                    </p>
                </div>
            )}
            {showPopup === "logout" && (
                <Popup
                    title="Confirm Logout"
                    message="Are you sure you want to log out?"
                    onClose={() => setShowPopup(null)}
                    onConfirm={handleLogout}
                    actionText="Yes, Logout"
                />
            )}
        </div>
    );
}

function Popup({ title, message, qrCodeDataUrl, onClose, onConfirm, actionText }) {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <h3>{title}</h3>
                <p>{message}</p>
                {qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code" />}
                <div className="popup-actions">
                    <button onClick={onClose} className="popup-cancel-button">
                        Close
                    </button>
                    {onConfirm && (
                        <button onClick={onConfirm} className="popup-action-button">
                            {actionText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

function ChatArea({ messages, loading }) {
    return (
        <div className="chat-area">
            {messages.map((message) => (
                <div
                    key={message.id}
                    className={`chat-message ${message.type} ${message.type === "bot" ? "left" : "right"}`}
                >
                    {message.text}
                </div>
            ))}
            {loading && (
                <div className="chat-message bot left typing-indicator">
                    <span>Thinking...</span>
                </div>
            )}
        </div>
    );
}

function ChatInput({ onSendMessage }) {
    const [input, setInput] = useState("");

    const handleSendMessage = () => {
        if (input.trim()) {
            onSendMessage(input);
            setInput("");
        }
    };

    return (
        <div className="chat-input">
            <input
                type="text"
                placeholder="Message ThinkingAI"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <button onClick={handleSendMessage}>&#8593;</button>
        </div>
    );
}

export default WelcomePage;
