import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Welcome-Pages/Details-Gathering.css";
import thinkingailogo from "../../images/thinkingai-logo.png";

function UserForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [agreed, setAgreed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.name && userData.email) {
            navigate("/welcome");
        }
    }, [navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name || !email) {
            setErrorMessage("Please fill in all the fields.");
            setIsErrorPopupOpen(true);
        } else if (!agreed) {
            setErrorMessage("Please accept the Privacy Policy.");
            setIsErrorPopupOpen(true);
        } else {
            localStorage.setItem("userData", JSON.stringify({ name, email, agreed }));
            navigate("/welcome");
        }
    };

    return (
        <div className="full-page-form">
            <img className="thinkingaiLogo" src={thinkingailogo} alt="ThinkingAI Logo" />
            <h1 className="form-title">Can I Have Your Details?</h1>
            <form onSubmit={handleSubmit} className="user-form">
                <label className="input-label">
                    Name
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="form-input"
                    />
                </label>
                <label className="input-label">
                    Email
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="form-input"
                    />
                </label>
                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        checked={agreed}
                        onChange={(e) => setAgreed(e.target.checked)}
                        className="form-checkbox"
                    />
                    {" I agree to the "}
                    <span className="privacy-link" onClick={() => setIsModalOpen(true)}>
                        Privacy Policy
                    </span>
                </label>
                <button type="submit" className="submit-button">
                    Let's Start
                </button>
            </form>

            {/* Privacy Policy Modal */}
            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button>
                        <h2>Privacy Policy for ThinkingAI</h2>
                        <p><strong>Last Updated:</strong> 11/11/2024</p>
                        <p>ThinkingAI (referred to as "we," "us," or "our") respects your privacy and is committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website, ThinkingAI.in ("the Site"), and when you engage with our services.</p>
                        <p>By accessing or using our services, you agree to the terms of this Privacy Policy.</p>

                        <h3>1. Information We Collect</h3>
                        <p>We collect the following types of information:</p>
                        <ul>
                            <li><strong>Personal Information:</strong> Name, email address, contact details, or any other data you provide voluntarily.</li>
                            <li><strong>Usage Data:</strong> Information about your interactions with our Site, such as IP address, browser type, pages visited, and time spent on each page.</li>
                            <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies to enhance user experience and analyze usage.</li>
                        </ul>

                        <h3>2. How We Use Your Information</h3>
                        <p>We may use your information for the following purposes:</p>
                        <ul>
                            <li><strong>To Provide and Improve Our Services:</strong> Personalizing your experience and improving our Site.</li>
                            <li><strong>Communication:</strong> Sending notifications, updates, and promotional content (with your consent).</li>
                            <li><strong>Analytics:</strong> Using data to analyze usage and improve functionality.</li>
                            <li><strong>Legal Compliance:</strong> Fulfilling any legal obligations, such as responding to legal requests.</li>
                        </ul>

                        <h3>3. Cookies and Tracking Technologies</h3>
                        <p>ThinkingAI uses cookies and similar technologies to track user activity and preferences. You may adjust your browser settings to refuse cookies or to alert you when cookies are being sent. Please note that disabling cookies may affect certain features of our Site.</p>

                        <h3>4. Sharing of Your Information</h3>
                        <p>We may share your data with:</p>
                        <ul>
                            <li><strong>Service Providers:</strong> Third-party vendors who assist in website functionality, analytics, and communication.</li>
                            <li><strong>Legal and Regulatory Authorities:</strong> When required by law or to protect our rights.</li>
                            <li><strong>Business Transfers:</strong> In the event of a merger, sale, or transfer of assets.</li>
                        </ul>
                        <p>Our third-party partners are bound by contractual obligations to protect the confidentiality and security of your information.</p>

                        <h3>5. Your Privacy Rights</h3>
                        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
                        <ul>
                            <li><strong>Access:</strong> You may request to access the personal data we hold about you.</li>
                            <li><strong>Correction:</strong> You can request corrections to your personal information if it is inaccurate.</li>
                            <li><strong>Deletion:</strong> You can request the deletion of your data under certain circumstances.</li>
                            <li><strong>Opt-Out:</strong> You have the right to opt out of marketing communications at any time.</li>
                        </ul>
                        <p>To exercise any of these rights, please contact us at founder@thinkingai.in.</p>

                        <h3>6. Data Security</h3>
                        <p>We take the security of your data seriously and have implemented measures to safeguard your information. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.</p>

                        <h3>7. Data Retention</h3>
                        <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. After the retention period, data is securely deleted or anonymized.</p>

                        <h3>8. Children’s Privacy</h3>
                        <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal data from children. If you believe we may have collected data from a child, please contact us immediately.</p>

                        <h3>9. Changes to This Privacy Policy</h3>
                        <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the "Last Updated" date at the top.</p>

                        <h3>10. Contact Us</h3>
                        <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
                        <p><strong>Email:</strong> founder@thinkingai.in</p>
                        <p><strong>Mailing Address:</strong> 5/45A, Bhavani, Erode, TN, India</p>
                    </div>
                </div>
            )}

            {/* Error Popup */}
            {isErrorPopupOpen && (
                <div className="error-popup-overlay" onClick={() => setIsErrorPopupOpen(false)}>
                    <div className="error-popup" onClick={(e) => e.stopPropagation()}>
                        <button className="error-close-button" onClick={() => setIsErrorPopupOpen(false)}>
                            &times;
                        </button>
                        <p>{errorMessage}</p>
                        <button onClick={() => setIsErrorPopupOpen(false)} className="error-ok-button">
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserForm;
